<template>
  <div>
    <div v-if="messages.length > 0" class="tw-flex tw-justify-end tw-flex-col tw-py-4" style="min-height: 550px">
      <div v-for="(item,index) in messages" :key="index">
        <div>
            <div class="tw-flex tw-items-start" :class="{'tw-justify-end': item.fromUser.id === user.id}">
              <v-avatar size="24" class="tw-h-6 tw-w-6 tw-mr-2" :title="item.fromUser.name">
                <v-img :src="item.fromUser.avatar"></v-img>
              </v-avatar>
              <div class="tw-rounded-xl tw-leading-relaxed tw-px-6 tw-py-2" :class="item.fromUser.id === user.id ?  'tw-bg-red tw-text-white': 'tw-bg-gray-n0'">{{ item.message }}</div>
                <v-card-actions class="text-xs">

                  <v-speed-dial
                      v-model="item.fab"
                      v-if="item.fromUser.id === user.id"
                      direction="right"
                      :open-on-hover="false"
                      transition="scale-transition"
                  >
                    <template v-slot:activator>
                      <v-btn
                          v-model="item.fab"
                          icon
                          x-small
                          :ripple="false"
                      >
                        <v-icon v-if="item.fab">
                          mdi-close
                        </v-icon>
                        <v-icon v-else>
                          mdi-dots-vertical
                        </v-icon>
                      </v-btn>
                    </template>

                    <v-btn
                        v-if="1==2"
                        icon
                        @click="selectedMsg = item.id"
                        x-small
                        color="indigo"
                    >
                      <v-icon>mdi-reply-all</v-icon>
                    </v-btn>
                    <v-btn

                        @click="destroy(item)"
                        icon
                        dark
                        x-small
                        color="red"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-speed-dial>


                </v-card-actions>
            </div>

          <!-- new reply on parent -->
          <div class="ml-1 mr-1" v-if="item.id === selectedMsg && 1==2">
            <v-card flat dense color="grey lighten-4">
            </v-card>
          </div>
          <v-card-actions class="text-xs" v-if="1==2">
<!--            <v-btn v-if="selectedMsg != item.id" x-small icon
                   color="indigo"
                   @click="selectedMsg = item.id">
              <v-icon>
                mdi-reply
              </v-icon>
            </v-btn>
            <v-btn v-else x-small icon @click="selectedMsg = null">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>-->

            <v-speed-dial
                v-model="item.fab"
                v-if="item.fromUser.id == user.id"
                direction="top"
                :open-on-hover="false"
                transition="scale-transition"
            >
              <template v-slot:activator>
                <v-btn
                    v-model="item.fab"
                    color="blue darken-2"
                    dark
                    icon
                    x-small
                >
                  <v-icon v-if="item.fab">
                    mdi-close
                  </v-icon>
                  <v-icon v-else>
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>

              <v-btn
                  icon
                  @click="selectedMsg = item.id"
                  x-small
                  color="indigo"
              >
                <v-icon>mdi-reply</v-icon>
              </v-btn>
              <v-btn

                  @click="destroy(item)"
                  icon
                  dark
                  x-small
                  color="red"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-speed-dial>


          </v-card-actions>
          <!-- Children start -->
          <div class="tw-space-y-4" v-if="item.children[0] && 1==2" >
            <div v-for="(citem,cindex) in item.children" :key="cindex">
              <div class="tw-flex tw-items-start" :class="{'tw-justify-end': citem.fromUser.id === user.id}">
                <v-avatar size="24" class="tw-h-6 tw-w-6 tw-mr-2" :title="citem.fromUser.name">
                  <v-img :src="citem.fromUser.avatar"></v-img>
                </v-avatar>
                <div class="tw-rounded-xl tw-leading-relaxed tw-px-6 tw-py-2" :class="citem.fromUser.id === user.id ? 'tw-bg-red tw-text-white' : 'tw-bg-gray-n0'">{{ citem.message }}</div>

                <v-card-actions class="text-xs">

                  <v-speed-dial
                      v-model="citem.fab"
                      v-if="citem.fromUser.id === user.id"
                      direction="top"
                      :open-on-hover="false"
                      transition="scale-transition"
                  >
                    <template v-slot:activator>
                      <v-btn
                          v-model="citem.fab"
                          icon
                          x-small
                          :ripple="false"
                      >
                        <v-icon v-if="citem.fab">
                          mdi-close
                        </v-icon>
                        <v-icon v-else>
                          mdi-dots-vertical
                        </v-icon>
                      </v-btn>
                    </template>

                    <v-btn
                        v-if="1==2"
                        icon
                        @click="selectedMsg = item.id"
                        x-small
                        color="indigo"
                    >
                      <v-icon>mdi-reply-all</v-icon>
                    </v-btn>
                    <v-btn

                        @click="destroy(citem)"
                        icon
                        dark
                        x-small
                        color="red"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-speed-dial>


                </v-card-actions>
              </div>

              <!-- new reply on child -->
              <div class="ml-1 mr-1" v-if="1==2 && citem.id == selectedMsg">
                <v-card flat dense color="grey lighten-4">
                  <v-form :ref="'form'+citem.id" v-model="citem.valid" :lazy-validation="lazy">
                    <v-textarea
                        background-color="white"
                        :rules="[rules.required,rules.maxlength(1500)]"
                        v-model="citem.body"
                        label="Message"
                        rows="1"
                        auto-grow
                        outlined
                    >
                    </v-textarea>

                    <v-card-actions>
                      <v-btn :disabled="!citem.valid" color="blue" x-small

                             @click="reply(citem)"
                             absolute
                             bottom
                             right

                      >
                        Send
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </div>

            </div>

          </div>
          <!-- Children end -->

         <v-form :ref="'form'+item.id" v-model="item.valid" :lazy-validation="lazy" class="tw-mt-6" @submit.prevent="reply(item)">
            <div class="tw-relative" v-if="1==2">
              <v-textarea
                  background-color="white"
                  :rules="[rules.required,rules.maxlength(1500)]"
                  v-model="item.body"
                  placeholder="Type your message here"
                  class="message-reply-form"
                  rows="1"
                  auto-grow
                  outlined
              >
              </v-textarea>
              <div>
                <button type="submit" :disabled="!item.valid" class="tw-absolute disabled:tw-opacity-20 tw-right-4 tw-top-6">
                  <send-icon class="tw-h-3 tw-w-3" stroke-width="1" />
                </button>
              </div>
            </div>
          </v-form> 


        </div>
      </div>

      <v-pagination
          v-if="!loading & 1==2"
          v-model="pegination"
          :length="meta.last_page"
          :total-visible="meta.total"
      >
      </v-pagination>
      <div>

        <v-btn v-if="pegination < meta.last_page"
               x-small text @click="pegination = pegination+1"
               :loading="loading"

               top
               right
        >{{ $t('notification.load_more') }}
        </v-btn>

      </div>

    </div>

<!-- Form -->
    <v-form ref="form" v-model="valid" :lazy-validation="lazy" class="tw-mt-6" @submit.prevent="newMsg()">
      <div class="tw-relative" v-if="1==1">
        <v-textarea
            background-color="white"
            :rules="[rules.required,rules.maxlength(1500)]"
            v-model="msg.message"
            placeholder="Type your message here"
            class="message-reply-form"
            rows="1"
            auto-grow
            outlined
        >
        </v-textarea>
        <div>
          <button type="submit" :disabled="!valid" class="tw-absolute disabled:tw-opacity-20 tw-right-4 tw-top-6">
            <send-icon class="tw-h-7 tw-w-7" stroke-width="1" />
          </button>
        </div>
      </div>
    </v-form>


<!-- Form end -->


  </div>
</template>

<script>

import { SendIcon } from 'vue-feather-icons';

export default {
  middleware: ['auth'],
  name: 'messages',
  props: ['vendor', 'user', 'chatId'],
  components: {
    SendIcon,
  },
  data() {
    return {
      fab: false,
      selectedMsg: 0,
      pegination: 1,
      meta: [],
      loading: false,
      cloading: false,
      valid: true,
      lazy: false,
      chat: null,
      msg: {
        message: null,
        to_user_id: null,
        product_id: null,
        parent_id: null,
        vendor_id: null
      },
      messages: [],
      rules: {
        required: v => !!v || 'This field is required',
        maxlength: len => v => (v || '').length <= len || `Invalid character max length ${len}`,
      }
    }
  },
  mounted() {
    this.getItem();
  },
  watch: {
    "pegination": function () {
      this.getItem();
    },
  },
  methods: {
    openDialogQuote(item) {
      this.$emit('quoteRequest', {
        item: item
      });

      this.chat = item;
      this.dialogQuote = true;
      console.log("dialog qoute");
    },
    async reply(item) {
      //console.log(item.id);
      //console.log(item.body);
      this.msg.message = item.body;
      this.msg.parent_id = item.id;
      this.msg.to_user_id = this.user.id === item.fromUser.id ? item.toUser.id : item.fromUser.id;
      await this.create();
      this.$refs['form' + item.id][0].reset();
      this.selectedMsg = null;
    },
    async newMsg() {
      await this.$refs.form.validate();
      if (!this.valid) return;
      await this.create();
    },
    create() {

      //console.log("create");
      this.cloading = true;

      let uri = '/api/messages';
      if (!this.msg.to_user_id) {
        this.msg.to_user_id = this.chatId;// this.vendor.id;
      }

      this.msg.vendor_id = this.vendor.id;

      this.$http.post(uri, this.msg)
          .then((response) => {
            //this.messages.push(response.data.data);
            this.addItem(response.data.data);
            this.$toast.success(response.data.message, "Success")
          }).finally(() =>
              this.msg.message = null,
              this.$refs.form.reset(),
              this.cloading = false
      );
    },
    getItem() {
      this.loading = true;
      let id = this.chatId; //this.vendor.id;// ? this.vendor.id:this.$route.params.id

      const params = {
        "page": this.pegination ? this.pegination : this.$route.query.page,
        //"filter[from_user_id]" : from_user_id,
      };

      let uri = '/api/messages/' + id;
      this.$http.get(uri, {params}).then((response) => {
        //this.messages.concat(response.data.data),
        this.messages = this.messages.concat(response.data.data);
        //this.messages = this.messages.filter(item => parseInt(item.fromUser.id) === parseInt(this.chatId));

        //todo sort this on BE
        /*
        this.messages.forEach((element) => {
          element.children.sort((a, b) => a.id - b.id);
        })
        */
        this.meta = response.data.meta
      }).finally(() => this.loading = false);
    },
    deleteItem(item) {
      let uri = '/api/messages/' + item.id;
      this.$http.delete(uri)
          .then((response) => {
            this.$toast.success(response.data.message, "Success", {timeout: 1000});
          }).finally(() => this.removeItem(item));

    },
    removeItem(i) {

      /*
      if (i.parent_id) {
        var indexp = this.messages.findIndex(item => parseInt(item.id) === parseInt(i.parent_id));
        //console.log(indexp);
        var indexc = this.messages[indexp].children.findIndex(item => parseInt(item.id) === parseInt(i.id));
        //console.log(indexc);
        this.messages[indexp].children.splice(indexc, 1);
      } else {
        var index = this.messages.findIndex(item => item.id === i.id);
        this.messages.splice(index, 1);
      }
      */
        var index = this.messages.findIndex(item => item.id === i.id);
        this.messages.splice(index, 1);

      


    },
    addItem(data) {
      //console.log(this.msg);
      var index = this.messages.findIndex(item => item.id === this.msg.parent_id);
      console.log(index);
      //console.log(data);
      /*
      if (index > -1) {
        //this.messages[index].children.push(data);
        this.messages.push(data);
      } else {
        this.messages.unshift(data);
      }
      */
      this.messages.push(data);

      this.msg.parent_id = null;
      this.msg.product_id = null;
      this.msg.to_user_id = null;
    },
    destroy(item) {
      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'address',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {

            this.deleteItem(item);

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }, true],
          ['<button>NO</button>', function (instance, toast) {

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }],
        ]
      });
    },

  }
};
</script>


<style scoped>
.message-blue {
  position: relative;
  margin-left: 20px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #A8DDFD;
  width: 200px;
  height: 50px;
  text-align: left;
  font: 400 .9em 'Open Sans', sans-serif;
  border: 1px solid #97C6E3;
  border-radius: 10px;
}

.message-orange {
  position: relative;
  margin-bottom: 10px;
  margin-left: calc(100% - 240px);
  padding: 10px;
  background-color: #f8e896;
  width: 200px;
  height: 50px;
  text-align: left;
  font: 400 .9em 'Open Sans', sans-serif;
  border: 1px solid #dfd087;
  border-radius: 10px;
}

.message-content {
  padding: 0;
  margin: 0;
}

.message-timestamp-right {
  position: absolute;
  font-size: .85em;
  font-weight: 300;
  bottom: 5px;
  right: 5px;
}

.message-timestamp-left {
  position: absolute;
  font-size: .85em;
  font-weight: 300;
  bottom: 5px;
  left: 5px;
}

.message-blue:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 15px solid #A8DDFD;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  top: 0;
  left: -15px;
}

.message-blue:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 17px solid #97C6E3;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  top: -1px;
  left: -17px;
}

.message-orange:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 15px solid #f8e896;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  bottom: 0;
  right: -15px;
}

.message-orange:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 17px solid #dfd087;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  bottom: -1px;
  right: -17px;
}

</style>