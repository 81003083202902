<template>
  <ml-container>
    <spinner v-if="loading"/>
    <div class="md:tw-grid md:tw-grid-cols-8 tw-divide-x tw-divide-gray-n1 tw-mb-6">
      <div class="tw-col-span-2">
        <div class="tw-border-b tw-border-gray-n1 tw-leading-relaxed tw-py-4 tw-h-24">
          <h2 class="tw-text-lg tw-font-semibold tw-mb-1">Messages</h2>
          <p class="tw-mb-0 tw-text-sm">
            Read all of your conversations here
          </p>
        </div>
        <div v-for="(chat,index) in chats" :key="index" class="tw-pt-4 tw-pr-6 tw-cursor-pointer hover:tw-bg-r" @click="selectedChat = chat">
          <div class="tw-flex tw-items-center">
            <v-avatar size="40" class="tw-w-10 tw-h-10 tw-mr-3">
              <v-img :src="chat.fromUser.avatar"></v-img>
            </v-avatar>
            <div>
              <h5 class="tw-mr-">{{chat.fromUser.name}}</h5>
              <span class="tw-text-xs tw-text-gray-n3">{{chat.last_created}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-col-span-6 tw-pl-6" v-if="selectedChat">
        <div class="tw-flex tw-items-center tw--ml-6 tw-border-b tw-border-gray-n1 tw-pl-6 tw-pb-4 tw-h-24">
          <v-avatar size="64" class="tw-w-16 tw-h-16 tw-mr-3">
            <v-img :src="selectedChat.fromUser.avatar"></v-img>
          </v-avatar>
          <div>
            <h5 class="tw-mr-">{{selectedChat.fromUser.name}}</h5>
            <span class="tw-text-xs tw-text-gray-n3">{{selectedChat.last_created}}</span>
          </div>
        </div>
        <Messages
            v-if="selectedChat"
            :key="selectedChat.from_user_id"
            :chat-id="selectedChat.from_user_id"
            :vendor=selectedChat.vendor
            :user=user
        ></Messages>
      </div>
    </div>
  </ml-container>
</template>


<script>


import Messages from '../components/Message.vue';

import { mapGetters } from 'vuex'
import MlContainer from "@/components/layout/MlContainer";
import i18n from '@/i18n'
//import Pusher from 'pusher-js';
//Pusher.logToConsole = true;

export default {
  middleware: ['auth'],
  components: {
    MlContainer,
    Messages
  },
  metaInfo() {
    return {
      title: i18n.t('user.navbar.messages'),
    }
  },
  data() {
    return {
      selectedChat: null,
      vendor: {},
      chats: [],
      loading: false,
      errorMsg: '',
      errored: null,
      messages: [],
      number: 0,
    }
  },
  mounted() {

    this.getChats();
    // this.hookPusher();
    // this.getVendor();

  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    fontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '3em';
        default:
          return '5em';
      }
    },
    miniSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true;
        case 'sm':
          return true;
        case 'md':
          return true;
        case 'lg':
          return false;
        case 'xl':
          return false;
        default:
          return false;
      }
    },
    maxSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return false;
        case 'sm':
          return false;
        case 'md':
          return false;
        case 'lg':
          return true;
        case 'xl':
          return true;
        default:
          return true;
      }
    }
  },
  watch: {
    vendor: function () {
      // this.showcasework = this.vendor.showcasework;
      // this.settings = this.vendor.settings;
    },

  },
  methods: {
    hookPusher() {


      //this.messages = this.dbMessages;

      this.$pusher.connection.bind('state_change', function (states) {
        console.log('state.current=%s', states.current);
      });


      var name = 'private-App.User.'.concat(this.user.id)
      //console.log(name);
      var channel = this.$pusher.subscribe(name);
      channel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', (data) => {
        console.log(data.mtype);
        if (data.mtype == 'message') {
          this.messages.push(data);
          this.number++;
          this.getChats();
        }

      });

      this.number = this.user.countUnreadNotifications;
      /*
      this.messages.forEach(element => {
          if(element.read_at === null){
              this.number++;
          }
      });
      */

    },
    getChats() {
      this.loading = true;

      let uri = '/api/chats';
      this.$http.get(uri)
          .then((response) => {
            this.chats = response.data.data;
            this.selectedChat = this.chats[0];
          })
          .catch(error => {
            this.errorMsg = error;
            this.errored = true;
          })
          .finally(() => this.loading = false);
    },
    getVendor() {
      this.loading = true;

      let uri = '/api/vendors/' + this.$route.params.id + "?include=companies,categories";
      this.$http.get(uri)
          .then((response) => {
            this.vendor = response.data.data
          })
          .catch(error => {
            this.errorMsg = error;
            this.errored = true;
          })
          .finally(() => this.loading = false);
    },

  }
}
</script>
